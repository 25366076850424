/* Import Minecraftia font */
@import url('https://fonts.cdnfonts.com/css/minecraftia');

.App {
  text-align: center;
  font-family: 'Minecraftia', sans-serif;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

/* Full-screen panorama container */
.panorama-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1; /* Ensure it stays behind all other content */
}

/* Make the logo twice as big and add spacing underneath */
.Minecraft-logo {
  width: 600px;
  margin-bottom: 30px;
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Main menu buttons with refined 3D shading effect */
.menu-button {
  box-sizing: border-box; /* Include padding and border in the element's width/height */
  width: 509px;
  padding: 6px;
  font-size: 18px;
  color: #fff;
  background-color: #555;
  border: 2px solid #000;
  cursor: pointer;
  transition: background-color 0.1s;
  box-shadow: inset 2px 2px 0px 1px #aaaaaa, inset -2px -2px 0px 1px #2d2d2d;
  outline: 1px solid #000;
  outline-offset: -3px;
  text-shadow: 2px 2px 2px #000000;
  text-decoration: none;
}

.menu-button:hover {
  background-color: #777;
}

.extra-button-container {
  display: flex;
  gap: 10px;
  margin-top: 40px;
}

.extra-button-container .menu-button {
  width: 250px;
}

.logo-container {
  position: relative;
  display: inline-block;
}

/* Overlay text styling */
.overlay-text {
  position: absolute;
  bottom: 90px;
  right: -80px;
  color: yellow;
  font-size: 16px;
  text-shadow: 1px 1px 2px #000;
  pointer-events: none;
  transform: rotate(-20deg);
  animation: grow-shrink 2s infinite ease-in-out;
}

@keyframes grow-shrink {
  0%, 100% {
    transform: rotate(-15deg) scale(1);
  }
  50% {
    transform: rotate(-15deg) scale(1.2);
  }
}

/* Media queries for smaller screens */
@media (max-width: 768px) {
  .Minecraft-logo {
    max-width: 70%;
  }

  .menu-button {
    width: 300px;
    font-size: 14px;
    padding: 10px;
  }

  .extra-button-container .menu-button {
    width: 100%; /* Stack buttons vertically */
  }

  .overlay-text {
    font-size: 12px;
  }
}