/* Import Minecraftia font */
@import url('https://fonts.cdnfonts.com/css/minecraftia');

/* Apply Minecraftia font to everything */
.art-page, .art-header, .art-overlay, .art-footer {
  font-family: 'Minecraftia', sans-serif;
}

.art-page {
  min-height: 100vh; /* Full height */
  margin: 0;
  display: flex;
  flex-direction: column; /* Ensure header, overlay, and footer stack vertically */
  justify-content: space-between; /* Distribute space evenly */
  box-sizing: border-box; /* Ensures padding is accounted for */
}

.art-header {
    position: fixed; /* Fixes the header at the top of the page */
    top: 0;
    width: 100%; /* Stretches the header across the page */
    background: inherit; /* Use the same dirt background as the page */
    color: #fff;
    text-align: center;
    padding: 10px;
    text-shadow: 2px 2px 4px #000;
    z-index: 10; /* Ensure the header stays above other content */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
}


  .art-overlay {
    flex-grow: 1;
    overflow-y: auto; /* Enable vertical scrolling */
    display: flex;
    flex-direction: column; /* Stack sections vertically */
    justify-content: flex-start;
    align-items: center;
    padding: 70px 0; /* Prevent content overlap with header and footer */
  margin-top: 50px; /* Push content below the header */
  margin-bottom: 60px; /* Push content above the footer */
    background: rgba(0, 0, 0, 0.5); /* Transparent black background for the overlay */
    gap: 60px; /* Add extra 40px between sections (20px + 40px) */
    color: #fff;
    text-shadow: 2px 2px 4px #000;
    box-sizing: border-box;
  }
  .art-overlay .art-section {
    width: 80%; /* Sections take up 80% of the horizontal space */
    margin: 0 auto; /* Center the sections horizontally */
    text-align: left;
  }

  .art-footer {
    position: fixed; /* Fixes the footer at the bottom of the page */
    bottom: 0;
    width: 100%; /* Stretches the footer across the page */
    background: inherit; /* Use the same dirt background as the page */
    color: #fff;
    text-align: center;
    padding: 20px;
    text-shadow: 2px 2px 4px #000;
    z-index: 10; /* Ensure the footer stays above other content */
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.5);
}
/* Make the Done button wider with a highly specific selector */
.art-footer .menu-button {
    width: 400px; /* Desired width */
    display: inline-block; /* Ensure proper box model behavior */
    text-align: center;
}
/* Style each section */
.art-overlay .art-section {
    width: 80%; /* Sections take up 80% of the horizontal space */
    margin: 0 auto; /* Center the sections horizontally */
    text-align: left; /* Align text within the sections */
  }
  
  .art-section h2 {
    margin-bottom: 10px;
    font-size: 1.5em; /* Adjust title size */
  }
  
  .art-section p {
    margin: 0;
    line-height: 1.5; /* Improve text readability */
  }